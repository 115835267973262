import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby';

import FormWithEmailInput from './FormWithEmailInput';
import ModalTrigger from '../HOC/ModalTrigger';
import TertiaryButton from './UIComponents/TertiaryButton';
import MailIcon from '../assets/icons/mail-open-outline.svg';

const TopArticleSideNews = (
  { 
    news, 
    showRatingService,
    showSellService,
    showTaxService 
  }) => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        tools: entries(section: "tool") {
          ... on Gatsby_tool_tool_Entry {
            uri
            toolThumbnail {
              url
            }
            headline
            description
            uniformResourceLocator
          }
          id
        }
      }
    }
  `);

  const { tools } = data.gatsby;
  var displayTools = [];

  tools.slice(0, 3).forEach(function (tool) {
    if(showRatingService === true && tool.uri === "funktionen/unternehmensbewertung") {
      displayTools.push(tool)
    }
    if(showSellService === true && tool.uri === "funktionen/verkaufsanalyse") {
      displayTools.push(tool)
    }
    if(showTaxService === true && tool.uri === "funktionen/steuerrechner") {
      displayTools.push(tool)
    }
  });

  return (
    <div className="grid w-full md:grid-cols-4 md:gap-5 xl:grid-cols-1 grid-flow-row-dense">
      {news.slice(0, 3).map(item => (
        <div key={item.id}>
          <Link
            className="group flex flex-row mb-12 justify-between md:flex-col xl:flex-row xl:mb-4"
            to={`/${item.uri}`}
          >
            <div className="duration-150 group-hover:text-blue-500 break-words w-full md:order-2 xl:order-1">
              <p className="text-13 mb-2">{item.category[0].title}</p>
              <h3 className="text-17 mb-2 w-5/6 max-w-xs hyphens sm:text-lg xl:text-17 xxl:text-xl xxl:w-10/12">
                {item.title}
              </h3>
            </div>
            <img
              src={item.thumbnailSmall[0].url}
              alt={item.title}
              className="object-cover h-24 w-24 object-center sm:h-28 sm:w-28 md:h-24 md:w-24 md:mb-2 md:order-1"
              loading="lazy"
            />
          </Link>
          <hr className="h-px border-none bg-black-700.5 mb-12 md:hidden xl:flex xl:mb-0" />
        </div>
      ))}
      {displayTools.map(item => (
        <div key={item.id}>
          <a
            className="group flex flex-row mb-12 justify-between md:flex-col xl:flex-row xl:mb-4"
            href={item.uniformResourceLocator}
          >
            <div className="break-words w-full md:order-2 xl:order-1 duration-150 group-hover:text-blue-500">
              <h3 className="text-17 mb-2 w-5/6 max-w-xs hyphens md:text-lg xl:text-17 xxl:text-xl xxl:w-10/12">
                {item.headline}
              </h3>
              <p className="text-15 w-5/6 hyphens sm:w-9/12 md:w-11/12 lg:w-10/12 xl:w-11/12 xxl:w-10/12">
                {item.description}
              </p>
            </div>
            <img
              src={item.toolThumbnail[0].url}
              alt={item.headline}
              className="object-cover h-24 w-24 object-center sm:h-28 sm:w-28 md:h-24 md:w-24 md:mb-2 md:order-1"
              loading="lazy"
            />
          </a>
          <hr className="h-px border-none bg-black-700.5 mb-12 md:hidden xl:flex xl:mb-0" />
        </div>
      ))}
      <div className="flex flex-col">
        <div className="flex flex-row justify-between md:flex-col xl:flex-row">
          <div className="break-words w-full sm:w-3/5 md:w-full md:order-2 xl:order-1">
            <h3 className="text-17 mb-2 w-5/6 max-w-xs md:text-lg xl:text-17 xxl:text-xl xxl:w-10/12">
              Newsletter abonnieren
            </h3>
            <p className="text-15 w-5/6 sm:w-9/12 md:w-11/12 lg:w-10/12 xl:w-11/12 xxl:w-10/12">
              Verpassen Sie keine spannenden Beiträge mehr!
            </p>
          </div>
          <MailIcon className="h-24 w-24 sm:h-28 sm:w-28 md:h-24 md:w-24 md:mb-2 md:order-1" />
        </div>
        <div>
          <div className="col-span-2 mt-10 md:hidden xl:flex xl:mt-3">
            <FormWithEmailInput
              url="https://hooks.zapier.com/hooks/catch/8845339/bmk0grk"
              wide
            />
          </div>
          <ModalTrigger
            className="hidden md:inline-block xl:hidden"
            form
            url="https://hooks.zapier.com/hooks/catch/8845339/bmk0grk"
            title="Newsletter"
            headline="Verpassen Sie keine Beiträge mehr!"
            text="Sie wollen regelmäßig spannende Artikel rund um das Thema Nachfolge lesen? Dann melden Sie sich hier für unseren kostenlosen Newsletter und verpassen Sie keine Beiträge mehr. Es erwarten Sie unter anderem nützliche Tipps, Inspirationen, Anekdoten bzw. Erfolgsgeschichten, Erklärungen wichtiger Fachbegriffe sowie Studien und umfangreiche Marktberichte. Sie können den E-Mail-Newsletter jederzeit wieder abbestellen."
          >
            <TertiaryButton className="mt-4">ABONNIEREN</TertiaryButton>
          </ModalTrigger>
        </div>
      </div>   
    </div>
  );
};

TopArticleSideNews.defaultProps = {
  showRatingService: false,
  showSellService: false,
  showTaxService: false
}

TopArticleSideNews.propTypes = {
  showRatingService: PropTypes.bool,
  showSellService: PropTypes.bool,
  showTaxService: PropTypes.bool,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      category: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
        })
      ),
      thumbnailSmall: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
}

export default TopArticleSideNews;

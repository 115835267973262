import React from 'react'
import { Helmet } from 'react-helmet';
import FAQEntry from '../components/FAQEntry.jsx'
import { faqsToSchemaJson } from '../utils/faqSchemaUtils';

const FAQSet = ({ faqSet }) => {
  const faqJSON = faqsToSchemaJson(faqSet)

  return (
    <section className="px-5 mt-8 mb-20 md:px-10 md:mt-16 md:mb-24 lg:mt-20">
        <div className="mx-auto max-w-screen-xxl">
            <Helmet>
                {Object.keys(faqJSON).length && (
                    <script type="application/ld+json">
                        {JSON.stringify(faqJSON)}
                    </script>
                )}
            </Helmet>
            <h3 className="text-center text-2xl mb-10 md:text-3.5xl">Haben Sie noch weitere Fragen?</h3>
            <h4 className="text-center text-xl mb-10 md:text-2.5xl">Unsere FAQ helfen Ihnen sicherlich weiter. Ansonsten zögern Sie nicht uns zu kontaktieren.</h4>
            <div className="justify-center">
                <div className="w-full grid grid-cols-1 gap-0 lg:grid-cols-2 lg:gap-12">
                    {faqSet.map(entry => (
                        <FAQEntry
                            key={entry.id}
                            text={entry.richText.replace(/<[^>]+>/g, '')}
                            richText={entry.richText}
                            title={entry.title}
                            isVisible={true}
                        />                        
                    ))}
                </div>
            </div>
        </div>
    </section>
  )
}

export default FAQSet

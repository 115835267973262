// returns faqs in json schema.org format

export const faqsToSchemaJson = (faqSet) => {
    let faqSchema = {}
    if (faqSet) {
        const FAQ = faqSet.map(faq => {
            return {
            "@type": "Question",
            name: faq.title,
            acceptedAnswer: {
                "@type": "Answer",
                text: faq.richText.replace(/<[^>]+>/g, ''),
            },
            }
        })
        faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [FAQ],
        }
    }
    return faqSchema
}
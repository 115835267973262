import React from 'react';
import { graphql } from 'gatsby';

import ArticleGrid from '../components/ArticleGrid';
import ContentBody from '../components/ContentBody';
import Layout from '../components/layout';
import TopArticleRow from '../components/TopArticleRow';
import SEO from '../components/seo';
import FAQSet from '../components/FAQSet.jsx'
import '../main.css';

const Index = ({ data }) => {
  const { homepage } = data.gatsby;

  return (
    <Layout>
      <SEO seomatic={homepage.seomatic} />
      <ContentBody>
        <div className="px-5 pt-4 md:px-0 md:pb-4 lg:pt-0 xl:pb-6 ">
            <h1 className="text-xl font-bold lg:text-2xl xl:w-4/5">
              Unternehmensverkauf &amp; Unternehmensnachfolge – dafür steht Nachfolge.de
            </h1>
        </div>
        <TopArticleRow
          topArticle={homepage.featured[0].topArticle[0]}
          showRatingService={homepage.featured[0].showRatingService}
          showSellService={homepage.featured[0].showSellService}
          showTaxService={homepage.featured[0].showTaxService}
          news={homepage.featured[0].newsSet}
        />
        <ArticleGrid articleRows={homepage.articleGrids} />
        {homepage.faqSet.length ? 
          <div>
            <hr className="h-px w-full my-3 border-none bg-black-700.5 lg:mb-16" />
            <FAQSet faqSet={homepage.faqSet} />
          </div>
          : null}
      </ContentBody>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    gatsby {
      homepage: entry(slug: "homepage") {
        ... on Gatsby_homepage_homepage_Entry {
          title
          seomatic {
            metaTitleContainer
            metaTagContainer
          }
          faqSet {
            ... on Gatsby_faq_faq_Entry {
              title
              richText
              id
            }
          }
          featured {
            __typename
            ... on Gatsby_featured_featuredSection_BlockType {
              topArticle {
                ... on Gatsby_articles_articles_Entry {
                  category {
                    title
                  }
                  title
                  excerpt
                  dateCreated
                  lastRevision
                  uri
                  thumbnailLarge {
                    url
                  }
                  thumbnailMedium {
                    url
                  }
                  thumbnailSmall {
                    url
                  }
                  articleAuthor {
                    ... on Gatsby_articleAuthor_articleAuthor_Entry {
                      nameField
                    }
                  }
                }
              }
              showRatingService
              showSellService
              showTaxService
              newsSet {
                ... on Gatsby_articles_articles_Entry {
                  id
                  title
                  uri
                  thumbnailSmall {
                    url
                  }
                  category {
                    title
                  }
                }
              }
            }
          }
          articleGrids: articleGrid {
            ... on Gatsby_articleGrid_articleRow_BlockType {
              headline
              articles: articleSet {
                ... on Gatsby_articleSet_article_BlockType {
                  cover
                  size
                  id
                  entry {
                    ... on Gatsby_articles_articles_Entry {
                      author: articleAuthor {
                        title
                      }
                      dateCreated
                      lastRevision
                      excerpt
                      title
                      uri
                      thumbnailSmall {
                        url
                      }
                      thumbnailMedium {
                        url
                      }
                      category {
                        title
                      }
                    }
                  }
                  id
                }
              }
              id
            }
          }
        }
      }
    }
  }
`;

export default Index;

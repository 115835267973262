import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// To use this overflow, add .relative to the parent
const ArticleOverflow = ({ visible }) => (
  <div
    className={classNames({
      'w-full h-full absolute inset-0 overflow-gray': true,
      inline: visible,
      'hidden sm:inline': !visible
    })}
  />
)

ArticleOverflow.propTypes = {
  visible: PropTypes.bool
}

export default ArticleOverflow

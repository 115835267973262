import React from 'react'
import PropTypes from 'prop-types'

import ArticleCard from './ArticleCard'
import { normalizeGridRow } from '../utils/gridUtils.js'

const ArticleGridRow = ({ row, isRelatedGridRow }) => {
  const currentRow = normalizeGridRow({ ...row })
  return (
    <div className="sm:mb-4 xl:mb-12">
      {currentRow.headline && (
        <div>
          <hr className="h-px w-full my-3 border-none bg-black-700.5 lg:mb-16" />
          {isRelatedGridRow ? 
            <h3 className="my-5 text-15 font-bold lg:text-base">
              {currentRow.headline}
            </h3> :
            <h2 className="my-5 text-15 font-bold lg:text-base">
              {currentRow.headline}
            </h2>
          }
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4">
        {currentRow.articles.map(article => (
          <div
            className={`${article.size === 'large' ? 'lg:col-span-2' : null}`}
            key={article.id}
          >
            {/* To resolve strange build errors */}
            {article.entry.length > 0 ? (
              <ArticleCard
                author={article.entry[0].author[0]?.title}
                category={article.entry[0].category[0].title}
                date={article.entry[0].lastRevision ? article.entry[0].lastRevision : article.entry[0].dateCreated}
                excerpt={article.entry[0].excerpt}
                size={article.size}
                thumbnail={article.entry[0].thumbnailSmall[0]?.url}
                title={article.entry[0].title}
                url={article.entry[0].uri}
                isRelatedGridRow={isRelatedGridRow}
                isNew={article.entry[0].isnew}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

ArticleGridRow.propTypes = {
  row: PropTypes.shape({
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        entry: PropTypes.arrayOf(
          PropTypes.shape({
            author: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string
              })
            ),
            category: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string
              })
            ),
            thumbnailSmall: PropTypes.arrayOf(
              PropTypes.shape({
                url: PropTypes.string.isRequired
              })
            ),
            thumbnailMedium: PropTypes.arrayOf(
              PropTypes.shape({
                url: PropTypes.string.isRequired
              })
            ),
            dateCreated: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            uri: PropTypes.string.isRequired
          })
        ),
        id: PropTypes.number.isRequired,
        size: PropTypes.string.isRequired
      })
    ),
    headline: PropTypes.string,
    id: PropTypes.number.isRequired
  })
}

export default ArticleGridRow

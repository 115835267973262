import React from 'react'
import PropTypes from 'prop-types'

import SmallArticleCard from './SmallArticleCard'
import LargeArticleCard from './LargeArticleCard'

const ArticleCard = ({
  author,
  category,
  date,
  excerpt,
  size,
  thumbnail,
  title,
  url,
  isRelatedGridRow,
  isNew
}) => {
  return (
    <>
      {size === 'large' ? (
        <LargeArticleCard
          title={title}
          excerpt={excerpt}
          thumbnail={thumbnail}
          author={author}
          date={date}
          url={url}
          category={category}
          isRelatedGridRow={isRelatedGridRow}
          isNew={isNew}
        />
      ) : (
        <SmallArticleCard
          title={title}
          excerpt={excerpt}
          thumbnail={thumbnail}
          author={author}
          date={date}
          url={url}
          category={category}
          isRelatedGridRow={isRelatedGridRow}
          isNew={isNew}
        />
      )}
    </>
  )
}

ArticleCard.propTypes = {
  author: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default ArticleCard

import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import FakeTertiaryLink from './UIComponents/FakeTertiaryLink'
import ArticleOverflow from './ArticleOverflow'

const LargeArticleCard = ({
  author,
  category,
  date,
  excerpt,
  thumbnail,
  title,
  url,
  isRelatedGridRow,
  isNew
}) => {
  return (
    <a href={`/${url}`} className="mb-auto" aria-label={title}>
      <article className="w-full group mb-12 sm:mb-4 sm:overflow-hidden">
        <div className="w-full relative">
          <img
            src={thumbnail}
            alt={title}
            className="w-full object-cover object-fit self-start max-h-20 lg:max-h-30"
            loading="lazy"
          />

          <ArticleOverflow />

          <div className="w-full flex flex-wrap flex-col mt-4 sm:absolute sm:bottom-0 sm:left-0 sm:p-4 lg:mt-6 lg:transform lg:transition-all lg:duration-150 lg:translate-y-8.5 lg:group-hover:translate-y-0                   ">
            {isNew && (
              <div className="mb-1">
                <span className="text-13 text-white bg-black-900 rounded-lg mr-1 p-1">
                  Neu
                </span>
              </div>
            )}
            {category && (
              <span className="text-13 w-full text-black-700.5 mb-1 sm:text-grey-300 sm:mb-2">
                {category}
              </span>
            )}
            {isRelatedGridRow ? 
              <h4 className="break-words w-full text-xl font-bold leading-tight mb-1 text-black-700 sm:text-white sm:mb-2 md:text-2xl xl:text-28 xl:w-3/4">
                {title}
              </h4>
              :
              <h3 className="break-words w-full text-xl font-bold leading-tight mb-1 text-black-700 sm:text-white sm:mb-2 md:text-2xl xl:text-28 xl:w-3/4">
                {title}
              </h3>
            }
            <div className="flex w-full text-13 text-black-700.5 sm:text-grey-300">
              {date && (
                <span className="mr-2">
                  {format(new Date(date), 'dd.MM.yyyy')}
                </span>
              )}
              {author && <span>{author}</span>}
            </div>
            <div className="w-full sm:hidden lg:flex lg:flex-col">
              <hr className="h-px w-12 my-3 border-none bg-black-700.5 lg:bg-grey-300" />
              <p className="w-full text-15 mb-2 text-black-700 lg:text-white">
                {excerpt}
              </p>
              {/* Swaps the link for the white one */}
              <div className="mr-auto lg:hidden">
                <FakeTertiaryLink text="Weiterlesen" />
              </div>
              <div className="hidden lg:block lg:mt-3 sm lg:transform lg:transition-all lg:ease-out lg:duration-150 lg:group-hover:mt-0">
                <FakeTertiaryLink text="Weiterlesen" white />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full hidden mt-4 sm:flex sm:flex-col lg:hidden">
          <p className="w-full text-15 mb-2 text-black-700">{excerpt}</p>
          <div className="mr-auto sm:transform sm:transition-all sm:duration-150 sm:translate-y-5 sm:group-hover:translate-y-0">
            <FakeTertiaryLink text="Weiterlesen" />
          </div>
        </div>
      </article>
    </a>
  )
}

LargeArticleCard.propTypes = {
  author: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default LargeArticleCard

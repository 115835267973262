import React from 'react'
import PropTypes from 'prop-types'

import ArticleGridRow from './ArticleGridRow'

const ArticleGrid = ({ articleRows }) => (
  <section className="w-full flex flex-col px-5 mt-10 sm:p-0">
    {articleRows.map(articleRow => (
      <ArticleGridRow row={articleRow} key={articleRow.id} />
    ))}
  </section>
)

ArticleGridRow.propTypes = {
  articleRows: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string,
      articles: PropTypes.array.isRequired,
      headline: PropTypes.string,
      id: PropTypes.number.isRequired
    })
  )
}

export default ArticleGrid

import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import ArticleOverflow from './ArticleOverflow'
import FakeTertiaryLink from './UIComponents/FakeTertiaryLink'
import useWindowWidth from '../hooks/useWindowWidth'

const TopArticleCard = ({
  author,
  category,
  date,
  excerpt,
  thumbnails,
  title,
  uri
}) => {
  const dateFormatted = format(new Date(date), 'dd.MM.yyyy')
  const width = useWindowWidth()
  let thumbnail = thumbnails.thumbnailSmall
  if (width > 640) {
    thumbnail = thumbnails.thumbnailMedium
  }

  return (
    <a href={`/${uri}`} aria-label={title}>
      <article className="flex flex-wrap w-full sm:relative xl:h-180 sm:overflow-hidden group">
        <img
          src={thumbnail}
          alt={title}
          className="w-full h-full object-cover object-fit self-start xl:h-180"
        />

        <ArticleOverflow />

        <div className="w-full py-2 flex flex-wrap px-5 sm:p-4 sm:absolute sm:bottom-0 sm:text-grey-300 sm:transform sm:translate-y-12 sm:transition-all sm:ease-out sm:duration-150 sm:group-hover:translate-y-0 lg:p-6">
          <span className="text-13 my-1 w-full text-black-700.5 sm:text-grey-300">
            {category}
          </span>
          <h2 className="w-full text-28 font-bold leading-tight sm:text-white sm:w-3/5 md:text-3xl xl:w-1/2">
            {title}
          </h2>
          <div className="w-full text-13 mt-2 text-black-700.5 sm:text-grey-300">
            <span className="mr-2">{dateFormatted}</span>
            <span>{author}</span>
          </div>
          <hr className="h-px w-12 my-3 border-none bg-black-700.5 sm:bg-grey-300" />
          <p className="w-full text-15 mb-2 sm:text-white">{excerpt}</p>
          {/* Links are swapped here */}
          <div className="hidden mr-auto sm:block sm:mt-4 sm sm:transform sm:transition-all sm:ease-out sm:duration-150 sm:group-hover:mt-0">
            <FakeTertiaryLink white text="Weiterlesen" />
          </div>
          <div className="sm:hidden mr-auto">
            <FakeTertiaryLink text="Weiterlesen" />
          </div>
        </div>
      </article>
    </a>
  )
}

TopArticleCard.propTypes = {
  author: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  thumbnails: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired
}

export default TopArticleCard

import React from 'react'

const ContentBody = ({ children }) => {
  return (
    <section className="bg-white sm:px-5 md:px-10 xl:pb-8 xl:pt-8">
      <div className="m-auto max-w-screen-xxl">{children}</div>     
    </section>
  )
}

export default ContentBody

import React from 'react';

import TopArticleCard from './TopArticleCard';
import TopArticleRelated from './TopArticleRelated';

const TopArticleRow = 
  ({ 
    news,
    showRatingService,
    showSellService,
    showTaxService,
    topArticle
  }) => {
  var thumnails = {
    thumbnailLarge: topArticle.thumbnailLarge[0].url,
    thumbnailMedium: topArticle.thumbnailMedium[0].url,
    thumbnailSmall: topArticle.thumbnailSmall[0].url,
  }

  return (
    <div className="grid grid-cols-1 gap-4 mt-6 md:mt-2 xl:grid-cols-8 xl:mb-16 xl:gap-6 xxl:gap-8">
      <div className="w-full xl:col-span-6">
        <div className="text-15 font-bold mb-4 px-5 sm:p-0 lg:text-base">
          Top Artikel
        </div>
        <TopArticleCard
          title={topArticle.title}
          excerpt={topArticle.excerpt}
          thumbnails={thumnails}
          author={topArticle.articleAuthor[0].nameField}
          date={topArticle.lastRevision ? topArticle.lastRevision : topArticle.dateCreated}
          uri={topArticle.uri}
          category={topArticle.category[0].title}
        />
        <hr className="h-px border-none bg-black-700.5 mt-12 mb-2 mx-5 sm:mx-0 xl:hidden" />
      </div>
      <div className="w-full px-5 sm:p-0 xl:col-span-2">
        <h2 className="text-15 font-bold mb-6 lg:text-base xl:mb-4">
          Neuigkeiten
        </h2>
        <TopArticleRelated
          news={news}
          showRatingService={showRatingService}
          showSellService={showSellService}
          showTaxService={showTaxService}
        />
      </div>
    </div>
  );
};

export default TopArticleRow;

import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import IosArrowDown from 'react-ionicons/lib/IosArrowDown'
import IosArrowUp from 'react-ionicons/lib/IosArrowUp'

const FAQEntry = ({ richText, title, isVisible }) => {
  const [visible, toggleVisibility] = useState(isVisible)
  const [mounted, mount] = useState(isVisible)

  const toggle = () => {
    if (!mounted) {
      mount(true)
      toggleVisibility(true)
    } else {
      toggleVisibility(false)
      setTimeout(() => mount(false), 300)
    }
  }

  return (
    <div className="w-full border-b border-grey-500.5">
      <button
        className="w-full flex cursor-pointer focus:outline-none"
        onClick={toggle}
      >
        <h4 className="text-black-900 w-4/5 mr-auto py-5 text-left">{title}</h4>
        <span className="ml-auto py-5">
          {mounted ? (
            <IosArrowUp
              color="rgba(29,29,27,0.5)"
              fontSize="24px"
              className="mr-1"
            />
          ) : (
            <IosArrowDown
              color="rgba(29,29,27,0.5)"
              fontSize="24px"
              className="mr-1"
            />
          )}
        </span>
      </button>

      {mounted && (
        <div
          className={`transform transition-all duration-300 ease-in-out ${
            visible ? 'menu-item' : 'menu-item max-h-0 opacity-0'
          }`}
        >
          <div className="pb-5">
            <div className="w-full"></div>
            <div className="text-15 mb-6 custom-article-content sm:mb-8 md:text-17 lg:mb-16 xl:text-xl">
              {ReactHtmlParser(richText)}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

FAQEntry.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isMounted: PropTypes.bool
}

export default FAQEntry
